import React, {useEffect, useState} from 'react';

import Header from '../components/header'
import Footer from '../components/footer'
import ItemSlider from '../components/itemSlider'
import DescriptionHome from '../components/descriptionHome'
import SkillRowRight from '../components/skillRowRight'
import SkillRowLeft from '../components/skillRowLeft'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";


function Home() {
    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = "MDS - Home";
    }, [])

    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
  return (
    <div className="App Home" id='hide'>

    <Header
        logo={require("../assets/logo.png")}
    />
    <Slider {...settings} className="slider_home">
        {/* <ItemSlider
            typeProjectSlider="video"
            imageProjectSlider={require("../assets/toyota.mp4")}
            thumbProjectSlider={require("../assets/toyota-thumb.png")}
            titleProjetSlider={`LUNA X TOYOTA`}
            descriptionProjetSlider="MUSIC VIDEO USING DEEP FAKES."
        /> */}
        <ItemSlider
            typeProjectSlider="image"
            imageProjectSlider={require("../assets/main-img-banner-home.jpg")}
            titleProjetSlider="MDS DIGITAL"
            descriptionProjetSlider=""
        />
    </Slider>
    <DescriptionHome 
        descriptionHome="Over the years we have evolved into not just another digital production agency, but a one-stop-shop for our clients and partners to be able to solve all their digital needs under one roof."
    />
    <div className="section-1">
        <div className="container special mb-5">
            <SkillRowLeft
                infoImgL={require("../assets/img-development.png")}
                infoTitleL="Development"
                infoTextL="Dream it, and together we will make it real."
                linkSkill="/projects"
                textSkill="see projects"
            />
            <SkillRowRight
                infoImgR={require("../assets/img-marketing.png")}
                infoTitleR="Social media and performance marketing"
                infoTextR="The art of telling stories."
                linkSkill="/projects"
                textSkill="see projects"
            />
            <SkillRowLeft
                infoImgL={require("../assets/img-ard.png")}
                infoTitleL="R&D"
                infoTextL="AI? NFT’s? Metaverse? What’s next and how to build it."
                linkSkill="/projects"
                textSkill="see projects"
            />
        </div>
    </div>

    <Footer />
    </div>
  );
}

export default Home;
