import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './styles/reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/header.scss';
import './styles/footer.scss';
import './styles/bannerIntern.scss';
import './styles/success-stories.scss';
import './styles/intern.scss';
import './styles/sliderHome.scss';
import './styles/components.scss';

import Home from "./pages/home";
import Projects from "./pages/projects";
import Miller from "./pages/miller";
import Gatorade from "./pages/gatorade";
import Toyota from "./pages/toyota";
import Skittles from "./pages/skittles";
import Netflix from "./pages/netflix";



function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/projects" element={<Projects />}/>
        <Route path="/projects/miller" element={<Miller />}/>
        <Route path="/projects/gatorade" element={<Gatorade/>} />
        <Route path="/projects/toyota" element={<Toyota />}/>
        <Route path="/projects/skittles" element={<Skittles/>} />
        <Route path="/projects/netflix" element={<Netflix />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
