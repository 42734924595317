import React from "react";

const DescriptionHome = (props) => {

    return(
        <div className="description_home">
            <p>{props.descriptionHome}</p>
        </div>
    );
        
}

export default DescriptionHome;