import React, {useState, useEffect, useRef} from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import emailjs from 'emailjs-com';

const ContactForm = (props) => {
    const [classActive, setClassActive] = useState('');
    const [success, setSuccess] = useState(false);
    const form = useRef();

    useEffect(() => {
        setClassActive(props.isActive ? 'active' : '');
    }, [props.isActive]);

    const handleContact = () => {
        document.body.style.overflowY = 'visible';
        document.body.style.height = 'unset';
        setClassActive('');
        props.onClose(false);
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email("Please enter a valid email")
            .required("Email is required"),
        name: Yup.string()
            .required("Name is required")
            .min(2, "Name too short! Must be at least 3 characters."),
        message: Yup.string(),
    });

    const handleSubmit = (values) => {
        emailjs.sendForm('service_1s0w3qv', 'template_fli7l0o', form.current, 'Y1Uv-7N2-8Rpsg9Wh')
        .then((result) => {
            setSuccess(true);
            setTimeout( () => {
                setSuccess(false);
            },4000);
        }, (error) => {
            console.log('error--: '+error.text);
        });
    };

    return(
        <div className={ `contact_form ${classActive}`} >
            <div onClick={handleContact} className="close"></div>
            <div className="container_form">
                <div className="title">Contact us.</div>
                <Formik
                    initialValues={{
                        email: "",
                        name: "",
                        message: "",
                    }}
                    onSubmit={(values, errors) => {
                        handleSubmit(values);
                        setTimeout( () => {
                            errors.resetForm();
                        },4000);
                    }}
                    validationSchema={validationSchema}
                >
                    {({ values, errors, dirty, isValid, isSubmitting, handleSubmit, handleChange }) => {
                        return (
                            <form onSubmit={handleSubmit} ref={form} className={`${errors.email || errors.name || errors.message? "error" : ""}`}>
                                <div className="field_box half">
                                    <label>Name*</label>
                                    <input type="text" name="name" placeholder="Write your name" value={values.name} onChange={handleChange("name")} className={`${errors.name? "error" : ""}`}/>
                                </div>
                                <div className="field_box half">
                                    <label>Email*</label>
                                    <input type="text" name="email" placeholder="Write your email" value={values.email} onChange={handleChange("email")} className={`${errors.email? "error" : ""}`}/>
                                </div>
                                <div className="field_box">
                                    <label>Tell us your idea</label>
                                    <textarea name="message" rows="3" placeholder="Write your message" value={values.message} onChange={handleChange("message")} className={`${errors.message? "error" : ""}`}/>
                                </div>
                                <div className="instruction">* Required fields.</div>
                                <div className={ `success ${success? 'active' :""}`}>We've received your message and will be in touch soon.</div>
                                <button type="submit" disabled={!(isValid && dirty)}>Send</button>
                            </form>
                        );
                    }}
                </Formik>
            </div>
            <div className="contact_info">
                <div className="box_contact">
                    <span>LOS ANGELES</span><br/>
                    <a href="callto:+13105841555">
                        +1 3105841555
                    </a><br/>
                    1450 2nd St. #101 Santa Monica, CA, 90401<br/>
                    <a href="mailto:info@mdsdigital.com">
                        info@mdsdigital.com
                    </a>
                </div>
                <div className="box_contact">
                    <span>BOGOTA</span><br/>
                    <a href="callto:+5717124001">
                        +57 1 7124001 Ext. 107
                    </a><br/>
                    Calle 90 # 18-35. of. 401. Bogota, D.C. Colombia<br/>
                    <a href="mailto:info-co@mdsdigital.com">
                        info-co@mdsdigital.com
                    </a>
                </div>
                <div className="box_contact">
                    <span>CDMX</span><br/>
                    <a href="callto:+525589101344">
                        +52 5589101344
                    </a><br/>
                    Sinaloa #195 – 210. Roma Norte, Cuauhtemoc. PO. 06700<br/>
                    <a href="mailto:info-mx@mdsdigital.com">
                        info-mx@mdsdigital.com
                    </a>
                </div>
            </div>
        </div>
    );
}

export default ContactForm;