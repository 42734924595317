import React from "react";
import { Link } from 'react-router-dom';

import AOS from 'aos';
import 'aos/dist/aos.css';

const InfoRowRight = (props) => {

    AOS.init();
    return(
        <div className="row" data-aos="fade-right" data-aos-offset="300">
            <div className="col-lg-8 col-sm-8 vertical-center">
                <div className="img-section">

                    {props.infoImgR === undefined ?
                       <></>
                    :
                        <img src={props.infoImgR} alt="MDS" />
                    }
                    {props.infoVideoR === undefined ?
                       <></>
                    :
                        <div className="contentVideo">
                            <iframe 
                                src={props.infoVideoR}
                                width="640" 
                                height="360" 
                                frameborder="0" 
                                allow="autoplay; fullscreen; picture-in-picture" 
                                allowfullscreen>
                            </iframe>
                        </div>
                    }
                    
                    

                </div>
            </div>

            <div className="col-lg-4 col-sm-4 vertical-center">
                <div>
                    <h1 className="section-titles skills">{props.infoTitleR}</h1>
                    <p className="infoSkill" dangerouslySetInnerHTML={{__html: props.infoTextR}}></p>
                    <Link className="link_animation" to={props.linkSkill}>
                        {props.textSkill}
                    </Link>
                </div>
            </div>
        </div>
    );
        
}

export default InfoRowRight;