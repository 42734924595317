import React, {useState, useEffect} from "react";
import { Link } from 'react-router-dom';

import ContactForm from '../components/contactForm';

const Header = (props) => {
    const [isActive, setActive] = useState(false);
    const [classActive, setClassActive] = useState('');
    const [isActiveContact, setActiveContact] = useState(false);

    useEffect(() => {
        setClassActive(isActive ? 'active' : '');
        setOverflow(isActive);
    }, [isActive]);

    const setOverflow = (hidden) => {
        if (hidden){
            document.body.style.overflowY = 'hidden';
            document.body.style.height = '100%';
        }else{
            document.body.style.overflowY = 'visible';
            document.body.style.height = 'unset';
        }
    }

    const handleAdvance = () => {
        setActive(!isActive);
    };

    const handleContact = () => {
        setOverflow(true);
        setActiveContact(!isActiveContact);
        setActive(!isActive);
    };

    const closeMenu = () => {
        setActive(!isActive);
    }

    return(
        <header className={ `${classActive}`}>
            <Link to="/" className="logo">
                <img src={props.logo} alt="MDS" />
            </Link>
            <div className='menu_box'>
                <nav>
                    <label htmlFor='drop' className='toggle'>
                        <span className='open' onClick={ handleAdvance }>&#9776;</span>
                    </label>
                    <input type='checkbox' id='drop' />
                    <ul className='menu'>
                        <li>
                            <Link to={"/"}>
                                Home
                            </Link>   
                        </li>
                        <li>
                            <Link to={"/projects"}>
                                Projects
                            </Link> 
                        </li>
                        <li>
                            <div onClick={handleContact}>
                                Contact Us
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
            <div className="menu_mobile">
                <label htmlFor='drop' className='toggle'>
                    <span className='closed' onClick={ handleAdvance }></span>
                </label>
                <ul className='menu'>
                    <li>
                        <Link to={"/"} onClick={closeMenu}>
                            Home
                        </Link>   
                    </li>
                    <li>
                        <Link to={"/projects"} onClick={closeMenu}>
                            Projects
                        </Link> 
                    </li>
                    <li>
                        <div onClick={handleContact}>
                            Contact Us
                        </div>
                    </li>
                </ul>
                <div className='social'>
                    <a href="https://www.facebook.com/weareMDS" target="_blank" className="fb"></a>
                    <a href="https://co.linkedin.com/company/mds-digital" target="_blank" className="lin"></a>
                    {/* <a href="https://twitter.com/mdsdigital" target="_blank" className="tw"></a> */}
                    <a href="https://www.instagram.com/mdsdigitalagencia" target="_blank" className="ins"></a>
                </div>
            </div>
            <ContactForm isActive={isActiveContact} onClose={setActiveContact}></ContactForm>
        </header>
    );
        
}

export default Header;