import React, {useState, useEffect} from "react";

const ItemSlider = (props) => {
    const [isActive, setActive] = useState(false);
    const [classActive, setClassActive] = useState('');

    useEffect(() => {
        setClassActive(isActive ? 'activeVideo' : '');
    });

    const toggleVideo = () => {
        setActive(!isActive);
    };

    return(
        <div className="item_slider container_box">
        {props.typeProjectSlider === 'image' ? 
            <div className="item_slider" style={{backgroundImage: `url(${props.imageProjectSlider})`, backgroundPosition: `center right`, backgroundRepeat: `no-repeat`, backgroundSize: `cover`}}>
                <div className="description_box">
                    <h3>{props.titleProjetSlider}</h3>
                    <p>{props.descriptionProjetSlider}</p>
                </div>
            </div>
        : <></>}
        {props.typeProjectSlider === 'video' ?
            <div className={ `item_slider video ${classActive}`} style={{background: `url(${props.thumbProjectSlider})`, backgroundPosition: `center`, backgroundRepeat: `no-repeat`}}>
                <div className="play_btn" onClick={toggleVideo}></div>
                <video width="400" loop="true" autoplay="autoplay" muted>
                    <source src={props.imageProjectSlider} type="video/mp4" />
                </video>
                <div className="description_box">
                    <h3>{props.titleProjetSlider}</h3>
                    <p>{props.descriptionProjetSlider}</p>
                </div>
            </div>
        : <></>}
        </div>
    );
        
}

export default ItemSlider;