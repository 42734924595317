import React from "react";
import { Link } from 'react-router-dom';

import AOS from 'aos';
import 'aos/dist/aos.css';

const InfoRowLeft = (props) => {

    AOS.init();
    return(
        <div className="row" data-aos="fade-left" data-aos-offset="300">

            <div className="col-lg-8 col-sm-8 vertical-center mobile">
                <div className="img-section">
                    <img className="float-right" src={props.infoImgL} alt="MDS" />
                </div>
            </div>
            
            <div className="col-lg-4 col-sm-4 vertical-center">
                <div>
                    <h1 className="section-titles skills">{props.infoTitleL}</h1>
                    <p className="infoSkill" dangerouslySetInnerHTML={{__html: props.infoTextL}}></p>
                    <Link className="link_animation" to={props.linkSkill}>
                        {props.textSkill}
                    </Link>
                </div>
            </div>

            <div className="col-lg-8 col-sm-8 vertical-center desktop">
                <div className="img-section float-right">
                    <img className="float-right" src={props.infoImgL} alt="MDS" />
                </div>
            </div>
        </div>
    );
        
}

export default InfoRowLeft;